<template>
  <div class="app">
    <header class="header">
      <AppHeader />
    </header>
    <main class="main">
      <router-view />
    </main>
    <footer v-if="!$store.getters['auth/isIntegration']">
      <ExperianFooter v-if="isExperian" />
      <AppFooter v-else />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
  components: {
    AppHeader: () => import('modules/layout/AppHeader'),
    AppFooter: () => import('modules/layout/AppFooter'),
    ExperianFooter: () => import('modules/layout/ExperianFooter')
  },
  computed: {
    isExperian() {
      return this.$experian
    }
  }
}
</script>

<style lang="sass" scoped>
.header
  border-top: solid 10px $nav-bar-top-border-color
  background-color: $navbar-background-color
</style>
