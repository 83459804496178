import Vue from 'vue'
import VueTippy from 'vue-tippy'

Vue.use(VueTippy, {
  arrow: true,
  duration: 300,
  theme: 'light bordered'
})

export default Vue
