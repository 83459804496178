const prod = process.env.NODE_ENV === 'production'
const experian = process.env.VUE_APP_TYPE === 'experian'
const cazana = process.env.VUE_APP_TYPE === 'cazana'

export const loadFontAwesome = () => {
  let css = document.createElement('link')
  css.href = 'https://pro.fontawesome.com/releases/v5.12.0/css/all.css'
  css.rel = 'stylesheet'
  css.type = 'text/css'
  css.crossorigin = 'anonymous'
  document.head.appendChild(css)
}

export const loadMixPanel = () => {
  const id = experian
    ? '3b59117b273d9548ba5f5d6b67ccc28f'
    : '01141d11938407987d1257de1a6f6642'

  if (prod) {
    let mixPanel = document.createElement('script')
    mixPanel.text = `
      (function(c,a){if(!a.__SV){var b=window;try{var d,m,j,k=b.location,f=k.hash;d=function(a,b){return(m=a.match(RegExp(b+'=([^&]*)')))?m[1]:null};f&&d(f,'state')&&(j=JSON.parse(decodeURIComponent(d(f,'state'))),'mpeditor'===j.action&&(b.sessionStorage.setItem('_mpcehash',f),history.replaceState(j.desiredHash||'',c.title,k.pathname+k.search)))}catch(n){}var l,h;window.mixpanel=a;a._i=[];a.init=function(b,d,g){function c(b,i){var a=i.split('.');2==a.length&&(b=b[a[0]],i=a[1]);b[i]=function(){b.push([i].concat(Array.prototype.slice.call(arguments,
      0)))}}var e=a;'undefined'!==typeof g?e=a[g]=[]:g='mixpanel';e.people=e.people||[];e.toString=function(b){var a='mixpanel';'mixpanel'!==g&&(a+='.'+g);b||(a+=' (stub)');return a};e.people.toString=function(){return e.toString(1)+'.people (stub)'};l='disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove'.split(' ');
      for(h=0;h<l.length;h++)c(e,l[h]);var f='set set_once union unset remove delete'.split(' ');e.get_group=function(){function a(c){b[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));e.push([d,call2])}}for(var b={},d=['get_group'].concat(Array.prototype.slice.call(arguments,0)),c=0;c<f.length;c++)a(f[c]);return b};a._i.push([b,d,g])};a.__SV=1.2;b=c.createElement('script');b.type='text/javascript';b.async=!0;b.src='undefined'!==typeof MIXPANEL_CUSTOM_LIB_URL?
      MIXPANEL_CUSTOM_LIB_URL:'file:'===c.location.protocol&&'//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js'.match(/^/)?'https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js':'//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js';d=c.getElementsByTagName('script')[0];d.parentNode.insertBefore(b,d)}})(document,window.mixpanel||[]);

      mixpanel.init('${id}');
    `
    document.head.appendChild(mixPanel)
  }
}

export const loadTypekit = () => {
  const typekit = document.createElement('script')
  typekit.src = `https://use.typekit.net/${experian ? 'kvm5dlz' : 'dfx7pwb'}.js`
  typekit.async = true
  typekit.onload = () => {
    try {
      // eslint-disable-next-line no-undef
      Typekit.load({ async: true })
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  document.head.appendChild(typekit)
}

export const loadGTM = (id) => {
  if (prod) {
    const gtm = document.createElement('script')
    gtm.text = `
    ;(function(w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      })
      var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', '${id}')
    `
    document.head.appendChild(gtm)
  }
}

export const loadHubspot = () => {
  if (prod) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'hs-script-loader'
    script.async = true
    script.defer = true
    script.src = 'https://js.hs-scripts.com/3803353.js'
    document.body.appendChild(script)
  }
}

export const loadOneTrust = () => {
  if (experian) {
    const wrapper = document.createElement('script')
    wrapper.type = 'text/javascript'
    wrapper.text = `function OptanonWrapper() {}`
    document.head.insertBefore(wrapper, document.head.firstElementChild)

    const cookieScript = document.createElement('script')
    cookieScript.src =
      'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js'
    cookieScript.setAttribute('data-document-language', true)
    cookieScript.type = 'text/javascript'
    cookieScript.charset = 'UTF-8'
    cookieScript.setAttribute(
      'data-domain-script',
      `39bb8656-49df-4b79-8768-624a7598ecd7${prod ? '' : '-test'}`
    )
    document.head.insertBefore(cookieScript, document.head.firstElementChild)

    const autoBlock = document.createElement('script')
    autoBlock.type = 'text/javascript'
    autoBlock.src =
      `https://cdn-ukwest.onetrust.com/consent/39bb8656-49df-4b79-8768-624a7598ecd7${prod ? '' : '-test'}/OtAutoBlock.js`
    document.head.insertBefore(autoBlock, document.head.firstElementChild)
  }
}

export default () => {
  loadFontAwesome()
  loadTypekit()
  loadMixPanel()
  loadGTM()
  loadHubspot()
}
