import Vue from 'vue'
import { stockview } from '@/plugins/axios'
import qs from 'qs'
const notify = (params) => Vue.prototype.$notify(params)

export const getListings = async (params) => {
  try {
    const { data } = await stockview.get('/listings', {
      params: {
        ...params.filters,
        limit: params.pageSize,
        sellerId: params.sellerId,
        sortBy: params.sortBy,
        sortOrder: params.sortOrder,
        valuationInsightType: params.valuationInsightType,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
    })
    return data
  } catch (err) {
    notify('Currently unable to retrieve your listings')
    throw err
  }
}

export const getFilterOptions = async (params) => {
  try {
    const { filter, sellerId, filters, valuationInsightType } = params

    const { data } = await stockview.get(`/listings/filters/${filter}`, {
      params: {
        ...filters,
        sellerId,
        valuationInsightType
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
    })
    return data
  } catch (err) {
    notify('Currently unable to retrieve your filters')
    throw err
  }
}
