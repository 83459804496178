<template>
  <div id="app">
    <ProgressBar />
    <div class="modal-background" :class="{ 'is-active': modalIsActive }" />
    <component :is="isIntegration ? 'static-layout' : layout" />
    <!-- GLOBAL COMPONENTS -->
    <transition
      enter-active-class="animated faster fadeIn"
      leave-active-class="animated faster fadeOut"
      mode="out-in"
    >
      <notify />
    </transition>
    <transition enter-active-class="animated faster zoomIn">
      <Modal v-if="modalIsActive" />
    </transition>
    <MobileNav v-if="mobNav && ($mq === 'mobile' || $mq === 'desktop')" />
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/Default'
import StaticLayout from '@/layouts/Static'
import {
  loadTypekit,
  loadFontAwesome,
  loadMixPanel,
  loadGTM,
  loadHubspot,
  loadOneTrust
} from 'core/utils/vendor'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: 'App',
  components: {
    DefaultLayout,
    StaticLayout,
    ProgressBar: () => import('modules/layout/AppProgressBar'),
    Modal: () => import('modules/modal/components/Modal'),
    MobileNav: () => import('./modules/layout/MobileNav')
  },
  computed: {
    ...mapState('overlay', ['mobNav']),
    ...mapGetters({
      modalIsActive: 'modal/active',
      isIntegration: 'auth/isIntegration'
    }),
    layout() {
      return `${this.$route.meta.layout || 'default'}-layout`
    }
  },
  watch: {
    modalIsActive() {
      if (this.modalIsActive && this.mobNav) {
        this.toggle()
      }
    }
  },
  methods: {
    ...mapMutations('overlay', ['toggle'])
  },
  created() {
    // Load vendor scripts
    loadGTM(process.env.VUE_APP_GTM_ID)
    loadOneTrust()
    loadHubspot()
    loadTypekit()
    loadFontAwesome()
    loadMixPanel()

    /**
     * Allow [esc] to close modal
     */
    window.addEventListener('keyup', event => {
      if (event.keyCode === 27) {
        this.$modal.close()
      }
    })
  }
}
</script>

<style lang="sass">
html, body, #app, .app
  height: 100%
.app
  display: flex
  min-height: 100%
  flex-direction: column
.main
  flex: 1 0 auto
.dropdown-menu
  &.has-arrow
    &.is-left
      &:before
        left: 10%
    &.is-right
      &:before
        right: 10%
    &:before
      content: ""
      display: block
      position: absolute
      top: 1%
      transform: rotate(45deg) translateX(-50%)
      background: #fff
      height: 1rem
      width: 1rem
      border-radius: .25rem 0 0 0
</style>

<style lang="sass" scoped>
.modal-background
  opacity: 0
  visibility: hidden
  position: fixed
  transition: visibility .5s, opacity .5s
  z-index: 39
  &.is-active
    opacity: 1
    visibility: visible
</style>
