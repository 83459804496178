export default {
  namespaced: true,
  state: {
    mobNav: false
  },
  mutations: {
    toggle(state) {
      state.mobNav = !state.mobNav
    }
  }
}
