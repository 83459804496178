import { guest } from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    user: null,
    org: null
  },
  getters: {
    form: state => {
      if (state.user) {
        delete state.user.password_confirmation
        return { user: state.user, org: state.org }
      }
    }
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_ORG(state, org) {
      state.org = org
    }
  },
  actions: {
    async submit({ getters }) {
      try {
        const { data } = await guest.post('/onboard/signup', getters.form)
        return data
      } catch (err) {
        throw err
      }
    }
  }
}
