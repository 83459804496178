import store from '@/store'

export const access = type => {
  const multiple = Array.isArray(type)

  if (multiple) {
    return type.every(t => !!store.getters[`auth/${t}`])
  }

  return store.getters[`auth/${type}`]
}

export const guard = (name, [router, config]) => {
  const valid = access(rules[name])

  if (!valid) {
    if (config?.logout) {
      store.commit('auth/unsetToken')
      return router[2]('/login')
    } else {
      return router[2]('/')
    }
  }

  return router[2]()
}

const rules = {
  guest: ['isGuest'],
  user: ['isUser'],
  multi: ['isUser', 'hasMulti'],
  briseis: ['isUser', 'hasMultiSummary'],
  stockview: ['isUser', 'hasStockview'],
  companion: ['isUser', 'isCompanionUser'],
  explorer: ['isUser', 'hasValuationExplorer'],
  branchAdmin: ['isBranchAdmin', 'branchHasAdmin'],
  autocheck: ['isUser', 'hasAutoCheck'],
  autocheckBatch: ['isUser', 'hasAutoCheckBatch'],
  autocheckValuation: ['isUser', 'hasAutoCheckValuation']
}
