import { node } from '@/plugins/axios'
import Vue from 'vue'

const notify = (params) => Vue.prototype.$notify(params)

export const getBranchUsers = async ({ query, req }) => {
  try {
    const params = { ...query, ...req }
    const { data } = await node.get('branch-admin/user', { params })
    return data
  } catch (err) {
    throw err
  }
}

export const resetUserPassword = async (userId) => {
  try {
    const path = 'branch-admin/reset-user-password'
    const type = process.env.VUE_APP_TYPE
    const params = { userId, type }
    await node.post(path, params)
    notify('Password reset email sent.')
  } catch (err) {
    const message = err?.response?.data?.error?.message
    const fallback = 'Uh oh. Something is up on our end.'
    notify(message || fallback)
    throw err
  }
}

export const removeBranchUser = async (userId) => {
  try {
    await node.post('branch-admin/remove-user', { userId })
    notify('User removed.')
  } catch (err) {
    notify('Uh oh. Something is up on our end.')
    throw err
  }
}

export const toggleStockviewEmail = async (userId, stockviewEmail) => {
  try {
    await node.patch(`branch-admin/toggle-stockview-email`, {
      userId,
      stockviewEmail
    })

    const text =
      stockviewEmail === 1
        ? 'User subscribed to the stockview daily report.'
        : 'User unsubscribed from the stockview daily report.'

    notify(text)
  } catch (err) {
    notify(`Uh oh. Something is up on our end.`)
    throw err
  }
}

export const createUser = async (user) => {
  try {
    const { data } = await node.post('branch-admin/add-user', { user })
    notify('User created')
    return data
  } catch (err) {
    const text =
      err?.response?.data?.error?.message || 'Uh oh. Something went wrong.'
    notify(text)
    throw err
  }
}

export const getOrgTable = async ({ query, req }) => {
  try {
    const params = { ...query, ...req }
    const { data } = await node.get('branch-admin/org-usage', { params })
    return data
  } catch (err) {
    throw err
  }
}

export const getOrgSummary = async ({ query }) => {
  try {
    const params = { ...query }
    const { data } = await node.get('branch-admin/summary', { params })
    return data
  } catch (err) {
    throw err
  }
}
