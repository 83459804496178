import { maxBy } from 'lodash'
import * as autocheck from '..'
import { formatDate } from '@/utils/date.utils.ts'

export const getLatest = (datedItems, key) => maxBy(datedItems, key) || {}

export const flattenData = response => {
  const { certificateDate } = getLatest(
    response.vehicleRegCertData?.vehicleRegCerts1,
    'certificateDate'
  )
  const { vicDate, vicResult } = getLatest(
    response.vehicleIDCheckData?.vehicleIDCheck1,
    'vicDate'
  )

  return {
    ...response.additionalSmmtData,
    ...response.vehicleExiseDutyData,
    ...response.weightAndDimensionData,
    ...response.performanceAndConsumptionData,
    ...response.engineAndTechnicalData,
    seatingCapacity: response.vehicleRegistration?.seatingCapacity,
    vicResult: vicResult === 0 ? 'Fail' : vicResult === 1 ? 'Pass' : null,
    vicDate: vicDate ? formatDate(vicDate) : null,
    certificateDate: certificateDate ? formatDate(certificateDate) : null
  }
}

export const getAdditionalData = response => {
  if (!response) return

  const data = flattenData(response)

  const additionalData = [
    {
      title: 'Performance and Consumption',
      icon: 'fa-tachometer',
      data: {
        'Power (kW)': data.powerKw,
        'Power (rpm)': data.powerRpm,
        'Power (bhp)': data.powerBhp,
        'Torque (lb ft)': data.torqueFt,
        'Torque (rpm)': data.torqueRpm,
        'Torque (Nm)': data.torqueNm,
        'CO2 Emissions (g/km)': data.co2,
        'CO2 Band': data.cO2Band,
        'Urban Cold (mpg)': data.urbanColdMpg,
        'Extra Urban (lkm)': data.extraUrbanLkm,
        'Extra Urban (mpg)': data.extraUrbanMpg,
        'Combined (mpg)': data.combinedMpg,
        'Urban Cold (lkm)': data.urbanColdLkm,
        'Combined (lkm)': data.combinedLkm,
        'Max Speed (mph)': data.maxSpeedMph,
        'Max Speed (kph)': data.maxSpeedKph,
        'Acceleration (0-60 mph) secs': data.accelerationMph,
        'Acceleration (0-100 kph) secs': data.accelerationKph
      }
    },
    {
      title: 'Engine and Technical',
      icon: 'fa-cogs',
      data: {
        'Driving Axle': data.drivingAxle,
        'Power Delivery': data.powerDelivery,
        'Type Approved Category': data.typeApprovalCategory,
        'Primary Fuel Flag': data.primaryFuelFlag,
        'Engine Capacity': data.engineCapacity,
        'Normal Engine Capacity': data.nominalEngineCapacity,
        'Engine Description': data.engineDescription,
        'Engine Location': data.engineLocation,
        'Engine Make': data.engineMake,
        'Euro Status': data.euroStatus,
        Bore: data.bore,
        Stroke: data.stroke,
        Aspiration: data.aspiration,
        'Cylinder Arrangement': data.cylinderArrangement,
        'Number of Cylinders': data.numberOfCylinders,
        'Number of Valves per Cylinder': data.valvesPerCylinder,
        'Valve Gear': data.valveGear,
        'Number of Doors': data.numberOfDoors,
        'Body Style': data.bodyStyle,
        'Cab Type': data.cabType,
        'Number of Gears': data.numberOfGears,
        'Fuel Type': data.fuelType,
        Transmission: data.transmission,
        'Drive Type': data.driveType
      }
    },
    {
      title: 'Weights and Dimensions',
      icon: 'fa-balance-scale-right',
      data: {
        'Body Shape': data.bodyShape,
        'Gross Combined Weight': data.grossCombinedWeight,
        'Gross Train Weight': data.grossTrainWeight,
        'Gross Vehicle Weight': data.grossVehicleWeight,
        Height: data.height,
        'Kerb Weight': data.kerbWeight,
        Length: data.carLength,
        'Load length': data.loadLength,
        'Number of Axles': data.numberOfAxles,
        'Number of Seats (mv)': data.numberOfSeats,
        'Payload Volume': data.payloadVolume,
        'Payload Weight': data.payloadWeight,
        'Rigid/Artic': data.rigidArtic,
        'Unladen Weight (mv)': data.unladenWeight,
        'Wheel Base': data.wheelBase,
        Width: data.width
      }
    },
    {
      title: 'Other',
      icon: 'fa-comment-alt-dots',
      data: {
        'Market Sector Code': data.marketSectorCode,
        Marque: data.marque,
        Range: data.range,
        Series: data.series,
        'Model Variant Name': data.modelVariant,
        'Country of Origin': data.countryOfOrigin,
        'Model Variant System Setup Date': data.sysSetupDate,
        'Model Variant Termination Date': data.terminateDate,
        'Model Variant Visibility Date': data.visibilityDate,
        'Engine Size': data.engineCapacity,
        'Seating Capacity Including Driver': data.seatingCapacity,
        'Latest V5 Issue Date': data.certificateDate,
        'VIC Test Date': data.vicDate,
        'VIC Test Result': data.vicResult
      }
    }
  ]
  const isMotorcyle = autocheck.motorcycle(
    response.vehicleRegistration?.doorPlanLiteral
  )
  return isMotorcyle
    ? additionalData.filter(group => group.title !== 'Other')
    : additionalData
}
