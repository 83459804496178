import * as Integrations from '@sentry/integrations'
import * as Sentry from '@sentry/browser'

import Vue from 'vue'

const prod = process.env.NODE_ENV === 'production'

if (prod) {
  Sentry.init({
    dsn: 'https://afb0330cbc014f96bff8e1fc1f1ac326@o4505425367400448.ingest.sentry.io/4505425369890816',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    ignoreErrors: ['InvalidStateError', 'Non-Error promise rejection captured']
  })
}
