<template>
  <div>
    <app v-if="isUser"></app>
    <guest v-else></guest>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Root',
  meta: {
    title: () => 'TEST'
  },
  computed: mapGetters({
    isUser: 'auth/isUser'
  }),
  components: {
    app: () => import('modules/dashboard/pages/Dashboard'),
    guest: () => import('modules/root/components/RootGuest')
  },
  mounted() {
    if (this.$experian && !this.isUser) {
      return this.$router.push({ name: 'auth-login' }).catch(() => {})
    }
  }
}
</script>
