import {
  format,
  isBefore,
  add,
  sub,
  formatRelative,
  getUnixTime,
  isSameDay,
  getYear,
  formatDistanceToNowStrict,
  differenceInYears,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isFuture
} from 'date-fns'

const validate = (value: Date | string | null | undefined) => {
  if (value === null) throw new Error('Invalid date')
}

const normalizeDate = (value: Date | string): Date => {
  if (typeof value === 'string') {
    return new Date(value)
  } else return value
}

export const formatDate = (
  date: Date | string,
  order: string = 'dd/MM/yyyy'
): string => {
  try {
    validate(date)
    return format(normalizeDate(date), order)
  } catch {
    return ''
  }
}

export const dateIsBefore = (
  firstDate: Date | string,
  secondDate: Date | string
): boolean => {
  validate(firstDate)
  validate(secondDate)
  return isBefore(normalizeDate(firstDate), normalizeDate(secondDate))
}

export const addTime = (date: Date | string, duration: object): Date => {
  validate(date)
  return add(normalizeDate(date), duration)
}

export const subtractTime = (date: Date | string, duration: object): Date => {
  validate(date)
  return sub(normalizeDate(date), duration)
}

export const relativeDate = (date: Date | string): string => {
  validate(date)
  return formatRelative(normalizeDate(date), new Date())
}

export const unixTime = (date = new Date()): number => {
  return getUnixTime(date)
}

export const sameDay = (date: Date | string): boolean => {
  validate(date)
  return isSameDay(normalizeDate(date), new Date())
}

export const getFullYear = (date: Date | string): number => {
  validate(date)
  return getYear(normalizeDate(date))
}

export const timeFromNow = (date: string): string => {
  validate(date)
  return formatDistanceToNowStrict(normalizeDate(date), {
    addSuffix: false
  })
}

export const timeAgo = (date: string): string => {
  validate(date)
  return formatDistanceToNowStrict(normalizeDate(date), {
    addSuffix: true
  })
}

export const yearsDifference = (firstDate: Date, secondDate: Date): number => {
  validate(firstDate)
  validate(secondDate)
  return differenceInYears(new Date(firstDate), new Date(secondDate))
}

export const daysDifference = (firstDate: Date, secondDate: Date): number => {
  validate(firstDate)
  validate(secondDate)
  return differenceInDays(new Date(firstDate), new Date(secondDate))
}

export const hoursDifference = (firstDate: Date, secondDate: Date): number => {
  validate(firstDate)
  validate(secondDate)
  return differenceInHours(new Date(firstDate), new Date(secondDate))
}

export const minutesDifference = (
  firstDate: Date,
  secondDate: Date
): number => {
  validate(firstDate)
  validate(secondDate)
  return differenceInMinutes(new Date(firstDate), new Date(secondDate))
}

export const secondsDifference = (
  firstDate: Date,
  secondDate: Date
): number => {
  validate(firstDate)
  validate(secondDate)
  return differenceInSeconds(new Date(firstDate), new Date(secondDate))
}

export const isFutureDate = (date: Date): boolean => {
  validate(date)
  return isFuture(new Date(date))
}
