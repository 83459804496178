export const tooltip =
  'The demand score out of 100 for this vehicle is calculated using supply and demand metrics and compared against the overall market'

export const getElementText = score => {
  if (score < 33.33) return `${score} (Low)`
  else if (score < 66.66) return `${score} (Medium)`
  else return `${score} (High)`
}

export const getElementColor = score => {
  if (score < 33.33) return 'danger'
  else if (score < 66.66) return 'warning'
  else return 'success'
}
