<template>
  <div>
    <notifications group="danger" width="auto" position="bottom left">
      <template slot="body" slot-scope="props">
        <div class="notification is-white" @click="props.close">
          <div class="columns is-vcentered is-mobile">
            <div class="column">
              <span class="tag is-danger is-light">
                <span class="heading has-text-weight-bold is-marginless">
                  Warning
                </span>
              </span>
              <span class="is-valigned">
                {{ props.item.text }}
              </span>
            </div>
            <div class="column is-narrow">
              <a class="icon is-small is-valigned has-text-grey-light">
                <i class="fal fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
    </notifications>
    <notifications group="success" width="auto" position="bottom left">
      <template slot="body" slot-scope="props">
        <div class="notification is-white" @click="props.close">
          <div class="columns is-vcentered is-mobile">
            <div class="column">
              <span class="tag is-success is-light">
                <span class="heading has-text-weight-bold is-marginless">
                  Success
                </span>
              </span>
              <span class="is-valigned">
                {{ props.item.text }}
              </span>
            </div>
            <div class="column is-narrow">
              <a class="icon is-small is-valigned has-text-grey-light">
                <i class="fal fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
    </notifications>
    <notifications width="auto" position="bottom left">
      <template slot="body" slot-scope="props">
        <div class="notification is-white" @click="props.close">
          <div class="columns is-vcentered is-mobile">
            <div class="column">
              <span class="tag is-info is-light">
                <span class="heading has-text-weight-bold is-marginless">
                  Info
                </span>
              </span>
              <span class="is-valigned">
                {{ props.item.text }}
              </span>
            </div>
            <div class="column is-narrow">
              <a class="icon is-small is-valigned has-text-grey-light">
                <i class="fal fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
export default {
  name: 'Notify'
}
</script>

<style lang="sass">
$spacing-unit: 1rem
.vue-notification-wrapper
  box-shadow: $box-shadow
  margin-bottom: $spacing-unit
.vue-notification-group
  margin: $spacing-unit
  .notification
    padding: $spacing-unit
    border: .125rem solid rgba(0, 0, 0, 0.1)
    .tag
      margin-right: $spacing-unit
</style>

<style lang="sass" scoped>
a.icon
  text-decoration: unset !important
</style>
