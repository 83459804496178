import { omit, set } from 'lodash'

export const storage = () => JSON.parse(window.localStorage.getItem('cz'))

const updateLocalStorage = state => {
  localStorage.setItem('cz', JSON.stringify(state))
}

export const getLocalStorage = (module, type) => {
  return storage()?.[module]?.[type]
}

export const setLocalStorage = (module, type, payload) => {
  if (!storage()) {
    updateLocalStorage({ [module]: { [type]: payload } })
  } else {
    const newState = set(storage(), `${module}.${type}`, payload)
    updateLocalStorage(newState)
  }
}

export const clearLocalStorage = (module, type) => {
  if (storage()) {
    const newState = omit(storage(), [`${module}.${type}`])
    return updateLocalStorage(newState)
  }
}
