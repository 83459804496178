export default {
  listings: state => state.listings,
  filters: state => state.params.filters,
  filterOptions: state => state.filterOptions,
  selectedFilters: state => state.selectedFilters,
  columns: state => state.columns,
  sortBy: state => state.params.sortBy,
  sortOrder: state => state.params.sortOrder,
  pageSize: state => state.params.pageSize,
  loading: state => state.loading,
  pricingStrategyItems: state => state.pricingStrategyItems
}