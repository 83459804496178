import { flattenDeep, get, pickBy, upperCase, uniqBy } from 'lodash'

export const getColourTypes = (aggs) => {
  if (!aggs) return []

  const buckets = get(aggs, 'colours.filtered_colours.buckets')
  return buckets.map((agg) => {
    return { label: agg.key }
  })
}

export const getDealerTypes = (aggs) => {
  if (!aggs) return []

  const path = 'dealers.filtered_dealers_'

  const franchiseBuckets = get(aggs, `${path}main.buckets`)
  const franchise = franchiseBuckets.filter((bucket) => bucket.key)

  const independentBuckets = get(aggs, `${path}independent.buckets`)
  const independent = independentBuckets.filter((bucket) => bucket.key)

  const supermarketBuckets = get(aggs, `${path}supermarket.buckets`)
  const supermarket = supermarketBuckets.filter((bucket) => bucket.key)

  const types = { franchise, independent, supermarket }
  return Object.keys(types).map((i) => ({ label: i, ...types[i][0] }))
}

export const getYears = (aggs) => {
  if (!aggs) return []

  const buckets = get(aggs, 'years.filtered_years.buckets')

  const years = []
  const generator = [...Array(50).keys()]
  generator.forEach((i) => {
    years.push(i, i + 50)
  })

  const sortedBuckets = buckets.sort(
    (a, b) => years.indexOf(a.key) - years.indexOf(b.key)
  )

  return sortedBuckets.map((item) => {
    item.key = item.key < 10 ? '0' + item.key.toString() : item.key
    return item
  })
}

export const getListingTypes = (aggs) => {
  if (!aggs) return []
  const sold = aggs.listing.sold.buckets.map((o) => ({
    label: o.key === 1 ? 'Sold' : 'Live',
    ...o
  }))
  const saleType = aggs.listing.saleType.buckets.map((o) => ({
    label: upperCase(o.key) === 'PRIVATE' ? 'Private' : 'Trade',
    ...o
  }))
  const other = [{ label: 'Young-used' }, { label: '12+ month old' }]
  const order = [
    'Sold',
    'Live',
    'Young-used',
    '12+ month old',
    'Trade',
    'Private'
  ]
  const options = [...sold, ...saleType, ...other]
    .sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label))
    .filter(({ doc_count }) => doc_count || doc_count === undefined)
  return uniqBy(options, 'label')
}
export const getActiveFilters = (aggs, getters) => {
  if (!aggs) return

  const { filters } = getters
  const activeFilters = pickBy(filters, (value) => value.length > 0)
  const keys = Object.keys(activeFilters)

  const location = getters['distance/location']
  if (location.coords.lat) keys.push('distance')

  return keys
}

export const flattenKeywords = (keywords) =>
  flattenDeep(keywords.map((k) => k.split(',')))

export const highlightKeywords = (keywords, text, extraClasses = '') => {
  const span = `<span class="has-text-weight-semibold${extraClasses}">`
  keywords.forEach(
    (k) => (text = text.replace(new RegExp(k, 'gi'), `${span}${k}</span>`))
  )
  return text
}

export const excerpt = (value, keywords) => {
  const arr = flattenKeywords(keywords)
  const exp = keywords.join('|').replace(',', '|')
  const str = value.toLowerCase()
  const regex = new RegExp(exp, 'gi')

  let result
  const indices = []

  while ((result = regex.exec(str))) indices.push(result.index)

  if (indices.length <= 0 || !value) return null

  let excerpt

  indices.forEach((i) => {
    const start = i - 20
    const end = i + 20

    const text = str.slice(start > 0 ? start : 0, end)

    const highlightedText = highlightKeywords(arr, text)

    excerpt += `...&ldquo;${highlightedText}&rdquo;...<br>`
  })
  return excerpt ? excerpt.replace(undefined, '') : null
}
