import { LoginState, LoginUser } from 'modules/login/login.types'
import {
  RESET,
  SET_STEP,
  SET_USER
} from 'modules/login/stores/login.mutation-types'

const initialState = (): LoginState => ({
  step: 1,
  form: {
    email: '',
    password: ''
  },
  user: {
    orgId: null,
    token: null,
    branches: null
  }
})

const state = initialState()

const mutations = {
  [SET_STEP](state: LoginState, value: number) {
    state.step = value
  },
  [SET_USER](state: LoginState, user: LoginUser) {
    state.user.branches = user.branches
    state.user.token = user.token
    state.user.orgId = user.branches.find(branch => branch.active)?.id || null
  },
  [RESET](state: LoginState) {
    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
