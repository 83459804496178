import * as Sentry from '@sentry/browser'

import { AxiosError } from 'axios'
import Vue from 'vue'
import { node } from '@/plugins/axios'
import store from '@/store'

declare module 'vue/types/vue' {
  interface Vue {
    $handleError: (err: AxiosError) => ErrorHandler
    // TODO: Add types to plugins/tracking for use here
    $mxp: any
    $reseller: 'cazana' | 'motors' | 'cartotrade' | 'experian'
    $client : 'cazana' | 'experian'
  }
}

const client = process.env.VUE_APP_TYPE

Vue.prototype.$prod = process.env.NODE_ENV === 'production'
Vue.prototype.$version = require('../../package.json').version
Vue.prototype.$reseller = client
Vue.prototype.$client = client === 'experian' ? 'experian' : 'cazana'
Vue.prototype.$experian = client === 'experian'
Vue.prototype.$cazana = client === 'cazana'
Vue.prototype.$motors = client === 'motors'
Vue.prototype.$cartotrade = client === 'cartotrade'
Vue.prototype.$mxp = require('@/plugins/tracking').default
Vue.prototype.$modal = {
  close: () => store.commit('modal/close'),
  open: (module: string, data: any) => {
    return store.commit('modal/open', [module, data])
  }
}
Vue.prototype.$node = node

class ErrorHandler {
  private _error
  constructor(error: AxiosError) {
    this._error = error
  }

  static fallback = 'Unable to complete request. Let us know if this persists.'

  errorMsg() {
    return this._error.response?.data?.error?.message
  }

  message() {
    return this.errorMsg() || ErrorHandler.fallback
  }

  notify(group: string) {
    Vue.prototype.$notify({
      group,
      message: this.message
    })
    return this
  }
}

Vue.prototype.$handleError = (error: AxiosError) => {
  if (!Vue.prototype.$prod) {
    /* eslint-disable no-console */
    console.log(error)
  }

  if (!error.response || error.response?.status === 500) {
    Sentry.captureException(error)
  }

  return new ErrorHandler(error)
}

export default Vue
