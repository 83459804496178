import { pounds } from 'core/utils/format'
import * as companion from 'modules/companion/services'
import * as marketDemand from '@/modules/shared/market-demand'
import {
  getIcons,
  getPriceInfo,
  openPriceChangeModal,
  priceColourClass
} from '../../services/stockview-listing.service'

export default function() {
  return {
    listings: null,
    filterOptions: {
      make: null,
      model: null,
      mileage: null,
      daysInStock: null,
      trim: null,
      price: null,
      pricePosition: null,
      registrationYear: null,
      desirability: null
    },
    columns: [
      {
        id: 'vrm',
        label: 'VRM',
        value: ({ vrm }) => vrm,
        sort: 'vrm',
        component: 'DataTableBlockButton',
        width: 150,
        display: true,
        getStyles: (_, permissions) =>
          !permissions.vehicleImage ? 'is-static' : '',
        getTooltip: () => 'View vehicle in Companion',
        getDisabled: (_, permissions) => !permissions.vehicleImage,
        onClick: async ({ vrm, mileage }) => {
          await companion.value({ vrm, mileage })
        },
        getWaitKey: ({ vrm }) => `companion-${vrm}`
      },
      {
        id: 'make',
        label: 'Make',
        value: ({ make }) => make,
        sort: 'make',
        width: 150,
        display: true
      },
      {
        id: 'model',
        label: 'Model',
        value: ({ model }) => model,
        sort: 'model',
        width: 150,
        display: true
      },
      {
        id: 'trim',
        label: 'Trim',
        value: ({ trim }) => trim,
        sort: 'trim',
        width: 150,
        display: true
      },
      {
        id: 'listingPrice',
        label: 'Listing price',
        value: ({ price }) => pounds(price),
        sort: 'price',
        width: 150,
        display: true,
        getStyles: (row) => {
          return [
            'tag',
            'has-text-weight-semibold',
            'is-rounded',
            priceColourClass(row)
          ].join(' ')
        }
      },
      {
        id: 'pricePosition',
        label: 'Price position',
        value: ({ percentageValue }) => `${Math.round(percentageValue)}%`,
        sort: 'pricePosition',
        width: 150,
        display: true,
        getTooltip: (row) => getPriceInfo(row).pricePositionTooltip,
        getIcon: (row) => getIcons(row).pricePosition,
        info: `
        <h5>Overview</h5>
        <p><strong>Price position</strong> shows the listing price as a percentage of the <strong>PVI Retail price</strong>, along with a flag indicating how appropriate the <strong>listing price</strong> is based on its time on sale.</p>
        <h5>Calculation</h5>
        <p>Firstly we split the <strong>price position</strong> into the below brackets:</p>
        <table>
          <thead>
            <tr>
              <th>Percentage of PVI Retail price</th>
              <th>Price position</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>105% and above</td>
              <td>Very high</td>
            </tr>
            <tr>
              <td>101-105%</td>
              <td>High</td>
            </tr>
            <tr>
              <td>99-101%</td>
              <td>On market</td>
            </tr>
            <tr>
              <td>95-99%</td>
              <td>Low</td>
            </tr>
            <tr>
              <td>95% and below</td>
              <td>Very low</td>
            </tr>
          </tbody>
        </table>
        <p>As new in stock vehicles have a higher likelihood to sell at a <strong>very high</strong> or <strong>high pricing position</strong>, and older vehicles a <strong>low</strong> or very <strong>low price position</strong>, we then compare how long the vehicle has been for sale to how long it takes on average to sell. It is then flagged with a recommendation as below:</p>
        <table>
          <thead>
            <tr>
              <th>Price position</th>
              <th colspan="4">Percentage of average days in stock</th>
            </tr>
            <tr>
              <th></th>
              <th>0-25</th>
              <th>25-50</th>
              <th>50-75</th>
              <th>75+</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Very high</td>
              <td class="has-text-success has-background-success-light">Price appropriate</td>
              <td class="has-background-warning-light">Consider price review</td>
              <td class="has-text-danger has-background-danger-light">Overpriced</td>
              <td class="has-text-danger has-background-danger-light">Overpriced</td>
            </tr>
            <tr>
              <td>High</td>
              <td class="has-text-success has-background-success-light">Price appropriate</td>
              <td class="has-text-success has-background-success-light">Price appropriate</td>
              <td class="has-background-warning-light">Consider price review</td>
              <td class="has-text-danger has-background-danger-light">Overpriced</td>
            </tr>
            <tr>
              <td>On Market</td>
              <td class="has-background-warning-light">Consider price review</td>
              <td class="has-text-success has-background-success-light">Price appropriate</td>
              <td class="has-text-success has-background-success-light">Price appropriate</td>
              <td class="has-background-warning-light">Consider price review</td>
            </tr>
            <tr>
              <td>Low</td>
              <td class="has-text-danger has-background-danger-light">Underpriced</td>
              <td class="has-text-success has-background-success-light">Price appropriate</td>
              <td class="has-text-success has-background-success-light">Price appropriate</td>
              <td class="has-text-success has-background-success-light">Price appropriate</td>
            </tr>
            <tr>
              <td>Very Low</td>
              <td class="has-text-danger has-background-danger-light">Underpriced</td>
              <td class="has-background-warning-light">Consider price review</td>
              <td class="has-background-warning-light">Consider price review</td>
              <td class="has-text-success has-background-success-light">Price appropriate</td>
            </tr>
          <tbody>
        </table>
        <h5>Example</h5>
        <p>A vehicle with an average days in stock of 43 days is at a <strong>price position</strong> of 106% (<strong>very high</strong>). At 6 days in stock (16% of average) the flag would be <strong>price appropriate</strong>. At 17 days in stock (40% of average) the flag would be <strong>consider price review</strong>.</p>
        <p><strong>If you want to change the default recommendations to your own pricing strategy, please contact business-support@percayso.com</strong></p>
      `
      },
      {
        id: 'suggestedPrice',
        label: 'Optimal price',
        value: ({ suggestedPrice }) => pounds(suggestedPrice),
        sort: 'suggestedPrice',
        width: 150,
        display: true,
        getStyles: () => {
          return [
            'tag',
            'has-text-weight-semibold',
            'is-rounded',
            'is-success'
          ].join(' ')
        },
        info: `
        <h5>Overview</h5>
        <p><strong>Optimal price</strong> shows a <strong>recommended listing price</strong>, using the <strong>days in stock</strong> to calculate an appropriate percent of the <strong>PVI Retail price</strong> for the amount of time on sale.</p>
        <h5>Calculation</h5>
        <table>
          <thead>
            <tr>
              <th>Percentage of average days in stock</th>
              <th>Percentage of PVI Retail price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0-25%</td>
              <td>103.5%</td>
            </tr>
            <tr>
              <td>25-50%</td>
              <td>100%</td>
            </tr>
            <tr>
              <td>50-75%</td>
              <td>98%</td>
            </tr>
            <tr>
              <td>75-100%</td>
              <td>96%</td>
            </tr>
          </tbody>
        </table>
      `
      },
      {
        id: 'mileage',
        label: 'Mileage',
        value: ({ mileage }) => mileage,
        sort: 'mileage',
        width: 150,
        display: true
      },
      {
        id: 'daysInStock',
        label: 'Days in stock',
        value: ({ daysInStock }) => daysInStock,
        sort: 'daysInStock',
        width: 150,
        display: true,
        getIcon: (row) => getIcons(row).daysInStock
      },
      {
        id: 'lastPriceChangeDate',
        label: 'Last price change',
        value: (row) => {
          const priceChangeInfo = getPriceInfo(row)
          if (!priceChangeInfo.priceChange) return undefined
          return priceChangeInfo.priceChangeText
        },
        sort: 'daysSinceLastPriceChange',
        width: 150,
        display: true,
        getIcon: (row) => getIcons(row).lastPriceChange,
        onClick: (row) => {
          if (getPriceInfo(row).priceChange) {
            openPriceChangeModal(row)
          }
        }
      },
      {
        id: 'vehicleStatus',
        label: 'Vehicle status',
        value: ({ vehicleStatus }) => vehicleStatus,
        width: 150,
        display: true,
        info: `
        <p>For example; retail, demo, ex-demo or pre-registered.</p>
        <p><strong>To use this functionality, you need to have an active stock feed. If you don’t have one already, please contact business-support@percayso.com</strong></p>
      `
      },
      {
        id: 'desirability',
        label: 'Market demand',
        value: ({ desirability }) => {
          if (desirability === null) return null
          else if (desirability < 33.33) return `${desirability} (Low)`
          else if (desirability < 66.66) return `${desirability} (Medium)`
          else return `${desirability} (High)`
        },
        sort: 'desirability',
        width: 150,
        display: true,
        getStyles: ({ desirability }) => {
          const color = marketDemand.getElementColor(desirability)
          return [
            'tag',
            'has-text-weight-semibold',
            'is-rounded',
            `is-${color}`
          ].join(' ')
        },
        info: `
        <p>The <strong>Market Demand</strong> is a score out of 100 showing how in-demand a vehicle is in the market right now.</p>
        <p>To calculate the market demand score we review how many vehicles have been sold to how many vehicles have been <strong>for sale</strong> in the last 30 days and then compare that to the overall market.</p>
      `
      }
    ],
    selectedFilters: [],
    params: {
      filters: {
        page: 1,
        make: null,
        model: null,
        mileageMin: null,
        mileageMax: null,
        trim: null,
        daysInStockMin: null,
        daysInStockMax: null,
        priceMin: null,
        priceMax: null,
        registrationYear: null,
        pricePosition: null,
        desirability: null,
        color: null,
        year: null,
        keyword: null,
        vrm: null,
        vehicleStatus: null
      },
      sortBy: "pricePosition",
      sortOrder: 'asc',
      pageSize: 15
    },
    loading: false,
    pricingStrategyItems: []
  }
}
