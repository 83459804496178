import store from '@/store'
import { node } from '@/plugins/axios'
import Vue from 'vue'

const notify = params => Vue.prototype.$notify(params)

export const updateUser = async user => {
  try {
    await node.patch(`/me/user`, { user })
    await store.dispatch('auth/getUser')
    notify({ group: 'success', text: 'Your details have been updated' })
  } catch (err) {
    notify({ group: 'danger', text: 'We were unable to update your details' })
    throw err
  }
}
