export default {
  setListings: (state, data) => (state.listings = data),
  setFilter: (state, { type, payload }) => {
    state.params.filters[type] = payload
  },
  setFilterOptions: async (state, { type, options }) => {
    state.filterOptions[type] = options
  },
  selectFilter: (state, { type, count }) => {
    const exists = state.selectedFilters.includes(type)
    if (!exists && count) {
      state.selectedFilters.push(type)
    } else if (exists && !count) {
      state.selectedFilters = state.selectedFilters.filter(i => i !== type)
    }
  },
  resetFilters: (state, data) => {
    state.params.filters = data
    state.selectedFilters = []
  },
  setSort: (state, { type, order }) => {
    state.params.sortBy = type
    state.params.sortOrder = order
  },
  setPageSize: (state, pageSize) => {
    state.params.pageSize = pageSize
  },
  resetPages: state => {
    state.params.filters.page = 1
  },
  setTableColumns: (state, columns) => {
    state.columns = columns
  },
  resetSort: state => (state.params.sortBy = null),
  toggleLoading: state => (state.loading = !state.loading),
  reset: (state, data) => Object.assign(state, data),
  setPricingStrategy: (state, items) => {
    state.pricingStrategyItems = items
  }
}