import { node } from '@/plugins/axios'

export const batchPrecheck = async rawData => {
  node.defaults.timeout = 120000

  try {
    const batch = getBatch(rawData)
    const { data } = await node.post('/autocheck/batch/precheck', { batch })
    return data
  } catch (err) {
    throw err
  } finally {
    node.defaults.timeout = 10000
  }
}

const getBatch = rawData => {
  const rows = rawData.filter(({ vrm, vin }) => vrm || vin)

  if (!rows?.length) {
    throw new Error('Please enter at least one row')
  }

  const batch = rows.map(({ vrm, vin, mileage }) => {
    return {
      vrm: vrm?.toUpperCase().replace(/\s/g, ''),
      vin: vin?.toUpperCase(),
      mileage: mileage ? Number(mileage.replace(/,/g, '')) : null
    }
  })
  return batch
}

export const batchAutoCheck = async params => {
  node.defaults.timeout = 120000

  try {
    const { data } = await node.post('/autocheck/batch/autocheck', params)
    return data
  } catch (err) {
    throw err
  } finally {
    node.defaults.timeout = 10000
  }
}
