import { node, guest } from '@/plugins/axios'
import Vue from 'vue'

import { logOut } from 'modules/auth/services'

const notify = params => Vue.prototype.$notify(params)

export const passwordRequest = async ({ email }) => {
  const reseller = process.env.VUE_APP_TYPE
  const params = { email, reseller }
  return guest.post(`/auth/password/request`, params)
}

export const passwordReset = async params => {
  return guest.post(`/auth/password/reset`, params)
}

export const updatePassword = async ({ request, headers }) => {
  try {
    await node.patch('/me/password', { request }, { headers })
    notify({
      group: 'success',
      text: 'Your password has been updated'
    })
  } catch (err) {
    if (err?.response?.status === 401) {
      return notify({
        group: 'danger',
        text: 'We were unable to update your password',
        duration: 5000
      })
    }
    if (err?.response?.status === 403) {
      notify({
        group: 'danger',
        text: 'You have been logged out for security reasons',
        duration: 5000
      })
      return logOut()
    }
    throw err
  }
}
