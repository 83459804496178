import { stockview } from '@/plugins/axios'

export * from './stockview-stock.service'
export * from './stockview-dashboard.service'
export * from './stockview-listing.service'

export const getSellers = async () => {
  try {
    const { data } = await stockview.get('/me/sellers')
    return data
  } catch (err) {
    throw err
  }
}
