import 'babel-polyfill'
import '@/plugins'
import '@/utils/service-worker'

import App from '@/App.vue'
import Vue from 'vue'
import VueWait from 'vue-wait'
import router from '@/router'
import store from '@/store'

const main = async () => {
  try {
    const auth = window.localStorage?.getItem('ctkn')
    if (auth) {
      await store.dispatch('auth/getInitialState')
    }
  } catch {
    store.dispatch('auth/logOut')
  }

  return new Vue({
    router,
    store,
    wait: new VueWait(),
    components: { App },
    render: h => h(App)
  }).$mount('#app')
}

main()
