import * as stockview from 'modules/stockview/services'

import state from './stockview-dashboard.state'

export default {
  async load({ getters, rootGetters, commit, dispatch }) {
    const sellers = rootGetters['auth/sellers']
    const isAdmin = rootGetters['auth/isAdmin']

    if (isAdmin) {
      const allSellers = await stockview.getSellers()
      commit('setSellers', allSellers)
    } else if (sellers.length) {
      commit('changeSeller', sellers[0])
    }

    if (getters.sellerId) dispatch('getSummary')
  },
  async getSummary({ commit, getters }) {
    const summary = await stockview.getSummary({ sellerId: getters.sellerId, sellerType: getters.sellerType })
    commit('setSummary', summary)
  },
  reset({ commit }) {
    commit('reset', state())
  },
  changeSeller({ commit, dispatch }, seller) {
    commit('changeSeller', seller)
    this.dispatch('stockview/listings/changeSeller')
    dispatch('getSummary')
  }
}
