import * as auth from 'modules/auth/services'

import Root from 'modules/root/pages/Root'
import Router from 'vue-router'
import Vue from 'vue'
import { guard } from 'utils/middleware'
import { router as stockview } from 'modules/stockview/stockview.router'
import store from './store'

Vue.use(Router)

export const registerModule = (name, storeModule) => {
  const exists = store._modules.root._children[name]
  if (!exists) store.registerModule(name, storeModule)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'root',
      meta: {
        title: () =>
          store.getters['auth/isUser'] ? 'My dashboard' : 'Trade Portal'
      },
      component: Root
    },
    {
      path: '/multi',
      component: () => import('modules/multi/pages/MultiDashboard'),
      meta: { title: () => 'Your uploads - Multi' },
      name: 'multi-dash',
      beforeEnter: (...router) => guard('multi', [router])
    },
    {
      path: '/multi/summary',
      component: () => import('modules/multi/pages/MultiSummary'),
      meta: { title: () => 'Summary - Multi' },
      name: 'multi-summary',
      props: true,
      beforeEnter: (...router) => guard('briseis', [router])
    },
    {
      path: '/vehicle/:vrm',
      component: () => import('modules/vehicle-profile/pages/VehicleProfile'),
      meta: { title: () => 'Vehicle Profile' },
      name: 'vehicle-profile',
      beforeEnter: (...router) => guard('user', [router])
    },
    {
      path: '/auth',
      component: { template: '<router-view/>' },
      beforeEnter: (...router) => guard('guest', [router]),
      children: [
        {
          path: '/login',
          meta: { title: () => 'Log in' },
          name: 'auth-login',
          component: () => import('modules/login/pages/Login')
        },
        {
          path: '/password/request',
          meta: { title: () => "I've forgotten my password" },
          name: 'auth-password-request',
          component: () =>
            import('modules/account/components/password/AuthPasswordRequest')
        },
        {
          path: '/password/reset',
          meta: { title: () => "I've forgotten my password" },
          name: 'auth-password-reset',
          component: () =>
            import('modules/account/components/password/AuthPasswordReset')
        }
      ]
    },
    {
      path: '/companion',
      component: { template: '<router-view/>' },
      beforeEnter: (...router) =>
        guard('companion', [router]),
      children: [
        { path: '', redirect: { name: 'root' } },
        {
          path: 'search/:uuid',
          meta: { title: () => 'Percayso Vehicle Intelligence Companion' },
          name: 'companion',
          component: () => import('modules/companion/pages/Companion')
        }
      ]
    },
    {
      path: '/explorer',
      component: { template: '<router-view/>' },
      beforeEnter: (...router) => guard('explorer', [router]),
      children: [
        {
          path: '',
          meta: { title: () => 'Valuation Explorer' },
          name: 'explorer',
          component: () => import('modules/explorer/pages/Explorer')
        }
      ]
    },
    stockview,
    {
      path: '/static',
      meta: { title: () => 'Companion', layout: 'static' },
      name: 'companion-static-search',
      beforeEnter: (to, from, next) => auth.hasToken(to, from, next),
      component: () =>
        import('modules/companion/components/static/CompanionStaticSearch')
    },
    {
      path: '/account',
      component: { template: '<router-view/>' },
      beforeEnter: (...router) => guard('user', [router]),
      children: [
        {
          path: 'activity/user',
          meta: { title: () => 'My activity' },
          name: 'account-activity-user',
          component: () => import('modules/account/pages/AccountActivityUser')
        },
        {
          path: 'activity/branch',
          meta: { title: () => 'Branch activity' },
          name: 'account-activity-branch',
          component: () => import('modules/account/pages/AccountActivityBranch')
        },
        {
          path: 'activity/organisation',
          meta: { title: () => 'Organisation activity' },
          name: 'account-activity-org',
          component: () => import('modules/account/pages/AccountActivityOrg'),
          beforeEnter: (...router) =>
            guard('branchAdmin', [router])
        },
        {
          path: 'details',
          meta: { title: () => 'Edit profile' },
          name: 'account-details',
          component: () => import('modules/account/pages/AccountDetails')
        },
        {
          path: 'admin',
          meta: { title: () => 'Branch admin' },
          name: 'account-branch-admin',
          component: () => import('modules/account/pages/AccountBranchAdmin'),
          beforeEnter: (...router) =>
            guard('branchAdmin', [router])
        }
      ]
    },
    {
      path: '/autocheck',
      component: { template: '<router-view/>' },
      children: [
        {
          path: 'batch',
          meta: { title: () => 'AutoCheck Batch' },
          name: 'autocheck-batch',
          component: () =>
            import('modules/autocheck-batch/pages/AutoCheckBatch'),
          beforeEnter: (...router) =>
            guard('autocheckBatch', [router])
        },
        {
          path: 'summary/:uuid',
          meta: { title: () => 'AutoCheck Summary' },
          name: 'autocheck-summary',
          component: () =>
            import('modules/autocheck-batch/pages/AutoCheckBatchSummary'),
          beforeEnter: (...router) =>
            guard('autocheckBatch', [router])
        },
        {
          path: ':uuid',
          meta: { title: () => 'AutoCheck' },
          name: 'autocheck',
          component: () => import('modules/autocheck/pages/AutoCheck'),
          beforeEnter: (...router) =>
            guard('autocheck', [router])
        }
      ]
    },
    {
      path: '/cookie-policy',
      component: () => import('modules/cookies/pages/CookiePolicy'),
      meta: { title: () => 'Cookie Policy' },
      name: 'cookie-policy'
    },
    { path: '*', component: () => import('core/components/NotFound') }
  ]
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)

  auth.checkTerms()
  auth.checkStatic(to.name, next)
  auth.checkSession(to, from, next)

  if (to.meta.title) {
    document.title = `${to.meta.title(to)} - Percayso Vehicle Intelligence`
  }

  next()
})

export default router
