export const percent = (dividend, divisor, places = 2) => {
  return parseFloat(((dividend / divisor) * 100).toFixed(places))
}

export const getMean = numbers => {
  return numbers.reduce((acc, val) => acc + val, 0) / numbers.length
}

export const getStandardDeviation = (numbers, mean, count) => {
  return Math.sqrt(
    numbers.reduce((sq, n) => {
      return sq + Math.pow(n - mean, 2)
    }, 0) /
      (count - 1)
  )
}

export const filterOutliers = (items, key, mean, severity, sd) => {
  return items.filter(item => Math.abs(item[key] - mean) <= severity * sd)
}
