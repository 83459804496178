import * as autocheck from '../services'

export default {
  response: (state) => state?.data?.response?.response,
  additionalData: (state, { response }) =>
    autocheck.getAdditionalData(response),
  request: (state) => state?.data,
  timeline: (state) => state?.timeline,
  make: (state, { response }) => {
    return (
      response?.vehicleRegistration?.make || response.additionalSmmtData?.marque
    )
  },
  previousChecks: (state) => state?.previousChecks,
  batch: (state) => state?.batch,
  photos: (state) => state?.image,
  fuelType: (state, { response }) => {
    return (
      response?.vehicleRegistration?.fuel ||
      response?.additionalSmmtData?.fuelType
    )
  },
  fuelPrices: () => ({
    dateUpdated: 'July 2021',
    petrol: 133.1,
    diesel: 135.0,
    lpg: 63.4
  }),
  fuelPrice: (state, { fuelType, fuelPrices }) => {
    return fuelPrices[fuelType.toLowerCase()]
  },
  fuelCost: (state, { response, fuelPrice }) => {
    const mpg = response?.performanceAndConsumptionData?.combinedMpg
    return (4.54609 * (12000 / parseFloat(mpg)) * (fuelPrice / 100)).toFixed(2)
  },
  hasFuelData: (state, { response }) => !!response.vehicleExiseDutyData.cO2Band,
  previousSearches: (state, { response }) => response?.previousSearchesDetails,
  vrm: (state, getters) => getters.request?.vrm,
  cid: (state, getters) => getters.cazana?.cid,
  vin: (state, { timeline, request }) => timeline?.vin || request?.vin,
  mileage: (state, getters) => getters.request?.mileage,
  mileageEstimated: (state, { request }) => request?.mileageEstimated,
  cap: (state, getters) => getters.request?.valuationCap,
  capToken: (state, getters) => getters.response?.valuationData?.token,
  glass: (state, getters) => getters.request?.valuationGlass,
  cazana: (state, getters) => getters.request?.valuationCazana,
  mileageData: (state, getters) => getters.request?.mileageData,
  date: (state, getters) => getters.request?.createdAt,
  description: (state, getters) => autocheck.buildDescription(getters.response),
  type96: (state, { warnings }) => warnings?.some(({ id }) => id === '15'),
  voidVrm: (state, { warnings }) => warnings?.some(({ id }) => id === '85'),
  noDvlaRegistration: (state, { warnings }) =>
    warnings?.some(({ id }) => id === '29'),
  condition: (state, { response }) => {
    const items = response?.conditionData?.conditionDetailsItems ?? []
    return autocheck.getCondition(items)
  },
  stolen: (state, { response }) => {
    const list = response?.conditionData?.conditionDetailsItems ?? []
    const data = list.filter(
      (item) => item.status === 'VEHICLE HAS BEEN STOLEN'
    )
    const altStolen = response?.stolenVehicleDetails?.status
    const status = !!(data.length || altStolen)

    return { status, data }
  },
  warnings: (state, { response }) => response?.messageDetails.messageList1,
  grid: (state, { response }) => autocheck.getGrid(response),
  specs: (state, { response }) => autocheck.getSpec(response),
  percentOfOcn: (state, { response }) => autocheck.getPercentOfOcn(response)
}
