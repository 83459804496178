import * as account from 'modules/account/services'
import * as stockview from 'modules/stockview/services'
import { stockview as stockviewAxios } from '@/plugins/axios'

import state from './stockview-listings.state'

export default {
  async getListings({ commit, state, rootGetters }) {
    const sellerId = rootGetters['stockview/dashboard/sellerId']
    const valuationInsightType = rootGetters['stockview/dashboard/sellerType']
    const params = { ...state.params, sellerId, valuationInsightType }
    try {
      const listings = await stockview.getListings(params)
      commit('setListings', listings)
    } catch {
      commit('resetSort')
    }
  },
  updateFilters({ commit, dispatch }, { filters, count, type }) {
    filters.forEach((filter) => commit('setFilter', filter))
    commit('selectFilter', { type, count })
    commit('resetPages')
    dispatch('getListings')
  },
  reset({ commit }) {
    commit('reset', state())
  },
  async setFilterOptions({ commit, state, rootGetters }, type) {
    const filters = { ...state.params.filters }
    Object.keys(filters).forEach((key) => {
      if (key.includes(type)) filters[key] = null
    })

    const sellerId = rootGetters['stockview/dashboard/sellerId']
    const valuationInsightType = rootGetters['stockview/dashboard/sellerType']
    const params = { filter: type, sellerId, valuationInsightType, filters }
    const response = await stockview.getFilterOptions(params)
    const options = { type, options: response.values || response }
    commit('setFilterOptions', options)
  },
  setPageSize({ commit, dispatch }, pageSize) {
    commit('setPageSize', pageSize)
    commit('resetPages')
    dispatch('getListings')
  },
  resetFilters({ commit, dispatch }) {
    const filters = state().params.filters
    commit('resetFilters', filters)
    dispatch('getListings')
  },
  getLocalColumnOverrides({ commit }) {
    const columnOverrides = account.getLocalStorage(
      'stockview',
      'columnOverrides'
    )
    if (!columnOverrides) return

    const columns = state().columns

    const newColumns = columns.map((column) => {
      const override = columnOverrides.find(({ id }) => id === column.id)
      if (!override) return column
      return { ...column, ...override }
    })

    commit('setTableColumns', newColumns)
  },
  setColumnOverride({ dispatch }, { id, key, value }) {
    let columnOverrides = account.getLocalStorage(
      'stockview',
      'columnOverrides'
    )
    if (!columnOverrides) columnOverrides = []

    const columnOverride = columnOverrides.find(
      (columnOverride) => id === columnOverride.id
    )

    if (!columnOverride) {
      columnOverrides.push({ id, [key]: value })
    } else {
      columnOverride[key] = value
    }

    account.setLocalStorage('stockview', 'columnOverrides', columnOverrides)
    dispatch('getLocalColumnOverrides')
  },
  async changeSeller({ dispatch }) {
    await dispatch('resetFilters')
    await dispatch('getListings')
    await dispatch('getLocalColumnOverrides')
  },
  async loadListings({ dispatch }) {
    await dispatch('getListings')
    await dispatch('getLocalColumnOverrides')
  },
  async getPricingStrategy({ commit }, sellerId) {
    try {
      const { data } = await stockviewAxios.get(`sellers/${sellerId}/pricing-strategies`, {
      })
      commit('setPricingStrategy', data?.items)
    } catch (err) {
      throw err
    }
  }
}