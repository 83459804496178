import * as auth from 'modules/auth/services'

import Vue from 'vue'
import { node } from '@/plugins/axios'
const notify = params => Vue.prototype.$notify(params)
const $experian = process.env.VUE_APP_TYPE === 'experian'

export default {
  async getInitialState({ commit, dispatch }) {
    const token = auth.getTokenFromStorage()

    if (!token) return null

    commit('setToken', token)
    return dispatch('getUser')
  },
  async getUser({ commit, getters }) {
    try {
      const { data } = await node.get('/me/user')
      commit('setUser', data.user)

      if (getters.isAdmin) return data
      else if ($experian && !getters.isExperian) {
        auth.logOut()
        notify('Please use the Percayso Trade Portal')
      } else if (!$experian && getters.isExperian) {
        auth.logOut()
        notify('Please use the Experian Trade Portal')
      } else return data
    } catch (err) {
      throw err
    }
  },
  async acceptTerms({ dispatch }) {
    try {
      await node.put('/me/terms')
      await dispatch('refresh')
    } catch (err) {
      throw err
    }
  },
  async logIn({ commit, dispatch }, credentials) {
    try {
      const { data } = await node.post('/auth/login', { credentials })

      commit('setToken', data.token)
      return dispatch('getUser')
    } catch (err) {
      commit('unsetToken')
      throw err
    }
  },
  async staticLogIn({ commit, dispatch }, token) {
    try {
      commit('setStaticToken', token)
      return dispatch('getUser')
    } catch (err) {
      commit('unsetToken')
      throw err
    }
  },
  async logOut({ commit }) {
    auth.clearAxiosAuth()
    return commit('unsetToken')
  },
  async refresh({ commit, dispatch }) {
    try {
      const { data } = await node.get('/auth/refresh')
      commit('setToken', data.token)
      const { token } = await dispatch('getUser')
      return token
    } catch (err) {
      commit('unsetToken')
    }
  }
}
