import * as Sentry from '@sentry/browser'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    if (registrations.length) {
      for (let registration of registrations) {
        Sentry.captureMessage(`Removing service worker ${registration.scope}`)
        registration.unregister()
      }
    }
  })
}
