import Vue from 'vue'
import { stockview } from '@/plugins/axios'
import { percent } from '@/services/MathsService'
const notify = (params) => Vue.prototype.$notify(params)

export const getSummary = async (params) => {
  try {
    const valuationInsightType = params.sellerType
    const sellerId = params.sellerId
    const { data } = await stockview.get(`/sellers/${sellerId}/summary`, {
      params: { valuationInsightType }
    })
    return data
  } catch (err) {
    notify('Currently unable to retrieve your data')
    if (err?.response?.status !== 401) throw err
  }
}

export const getPercentages = (total, counts) => {
  if (!total || !counts) return null

  const percentages = {}
  for (let type in counts) {
    percentages[type] = percent(counts[type], total, 10)
  }
  return percentages
}

export const compareValues = ({ totalPrice, totalSuggestedPrice }) => {
  if (!totalPrice || !totalSuggestedPrice) return null

  const percentage = percent(totalPrice, totalSuggestedPrice)
  const percentageDifference = percentage - 100
  const status = percentageDifference < 0 ? 'below' : 'above'
  const valueDifference = Math.abs(totalPrice - totalSuggestedPrice)
  const onMarket = totalPrice === totalSuggestedPrice
  return {
    percentageDifference: Math.abs(percentageDifference),
    status,
    valueDifference,
    onMarket
  }
}

const getAvailableValues = (values) => {
  return values.filter((entry) => entry[0] !== 'notAvailable')
}

const getAvailableTotal = (values) => {
  return values.map((arr) => arr[1]).reduce((acc, val) => acc + val)
}

const adjustValues = (values, total) => {
  return values.map((entry) => [entry[0], (entry[1] / total) * 100])
}

const getFinalValues = (values, keyMap) => {
  return values.map((entry) => {
    return { key: keyMap[entry[0]], value: entry[1] }
  })
}

export const getBarValues = (percentages, keyMap) => {
  if (!percentages) return null
  const entries = Object.entries(percentages)
  const availableValues = getAvailableValues(entries)
  const total = getAvailableTotal(availableValues)
  const adjustedValues = adjustValues(availableValues, total)
  return getFinalValues(adjustedValues, keyMap)
}

export const getBreakdown = (counts, percentages, labels) => {
  if (!counts || !percentages) return null
  const countValues = Object.values(counts)
  const percentageValues = Object.entries(percentages)

  return countValues.map((count, index) => {
    const identifier = percentageValues[index][0]
    const percent = percentageValues[index][1]
    const label = labels[identifier]

    return { count, percent, identifier, label }
  })
}
