import signup from 'modules/auth/stores/signup'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  modules: { signup },
  state: { jwt: null, user: null },
  getters,
  mutations,
  actions
}
