import actions from './stockview-dashboard.actions'
import getters from './stockview-dashboard.getters'
import mutations from './stockview-dashboard.mutations'
import state from './stockview-dashboard.state'

export default {
  namespaced: true,
  state: state(),
  getters,
  mutations,
  actions
}
