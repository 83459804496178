import Vue from 'vue'
import Vuex from 'vuex'
import auth from 'modules/auth/stores'
import autocheck from 'modules/autocheck/stores'
import login from 'modules/login/stores/login.store.ts'
import modal from 'modules/modal/stores/modal.store'
import overlay from 'modules/layout/stores'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    overlay,
    autocheck,
    auth,
    modal,
    login
  }
})
