import { node } from '@/plugins/axios'

export const getBranches = async () => {
  try {
    const { data } = await node.get('/me/branch')
    return data?.branches
  } catch (err) {
    throw err
  }
}
