import Vue from 'vue'
import { formatDate } from '@/utils/date.utils.ts'

import { node } from '@/plugins/axios'
import router from '@/router'
const notify = (params) => Vue.prototype.$notify(params)

export * from './similarListings'

export const value = async (params) => {
  const {
    vrm,
    mileage,
    date: queryDate,
    namaGrade,
    options,
    isStatic,
    customerReference,
  } = params
  try {
    const date = queryDate
      ? formatDate(queryDate, 'yyyy-MM-dd')
      : formatDate(new Date(), 'yyyy-MM-dd')

    const search = { vrm, mileage, date, namaGrade, options, customerReference }
    const { data } = await node.post('/companion', { search })
    const params = { uuid: data.uuid }
    router.push({ name: 'companion', params }).catch(() => {})
  } catch (err) {
    const message = err?.response?.data?.error?.message
    notify(message || 'Currently unable to perform a lookup for this vehicle')
    const errors = [
      'Cazana ID not available for this vehicle',
      'Valuation unavailable',
      'We are currently unable to value this vehicle'
    ]
    const vpp = errors.includes(message)
    if (vpp && !isStatic) {
      router.push({ name: 'vehicle-profile', params: { vrm } }).catch(() => {})
    }
  }
}

export const getOptions = async (params) => {
  try {
    const { data } = await node.get('/options', { params, timeout: 15000 })
    return data
  } catch (err) {
    const message = err?.response?.data?.error?.message
    notify(message || 'Unable to get options at this time')
    return {}
  }
}

export const makeHasOptions = ({ make }) => {
  const makes = [
    'Audi',
    'Bentley',
    'BMW',
    'Chrysler',
    'Citroen',
    'Dodge',
    'Ford',
    'Hyundai',
    'Jaguar',
    'Jeep',
    'Kia',
    'Land Rover',
    'Maybach',
    'Mercedes-Benz',
    'MINI',
    'Opel',
    'Peugeot',
    'Porsche',
    'Renault',
    'Rolls-Royce',
    'SEAT',
    'Skoda',
    'Smart',
    'Vauxhall',
    'Volkswagen',
    'Volvo'
  ]
  return makes.includes(make)
}

const pricePositionMap = {
  PricedWell: {
    colour: 'success',
    text: 'Price appropriate for time on market',
    tag: 'Priced Well'
  },
  UnderPriced: {
    colour: 'danger',
    text: 'Underpriced for time on market',
    tag: 'Change Price'
  },
  OverPriced: {
    colour: 'danger',
    text: 'Overpriced for time on market',
    tag: 'Change Price'
  },
  ConsiderReview: {
    colour: 'warning',
    text: 'Consider price review soon',
    tag: 'Consider Review'
  }
}

export const getPricePosition = ({ listing }) => {
  const { pricePositionPercentage, pricePosition } = listing
  if (!pricePositionPercentage || !pricePosition) return null
  const rounded = Math.round(pricePositionPercentage)
  return { rounded, ...pricePositionMap[pricePosition] }
}
