import * as auth from 'modules/auth/services'

import decode from 'jwt-decode'
import mxp from '@/plugins/tracking'

export default {
  setStaticToken(state, token) {
    const { iat } = decode(token)

    if (iat) state.jwt = { token, iat }

    auth.setAxiosAuth(token)
  },
  setToken(state, token) {
    state.jwt = { token, ...decode(token) }
    auth.setAxiosAuth(token)
    auth.saveTokenToStorage(token)
  },
  unsetToken(state) {
    state.jwt = null
    state.user = null
    auth.removeTokenFromStorage()
  },
  setUser(state, user) {
    try {
      mxp.identify(user.id)
      mxp.set(user)
    } finally {
      state.user = user
    }
  }
}
