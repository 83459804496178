import { getPercentages, compareValues } from 'modules/stockview/services'

export default {
  seller: state => state.seller,
  sellerId: state => state.seller?.sellerId,
  sellerType: state => state.seller?.sellerType,
  adminSellers: state => state.adminSellers,
  summary: state => state.summary,
  vehiclesOnSale: state => state.summary?.vehiclesOnSale,
  averagePrice: state => state.summary?.averagePrice,
  totalPrice: state => state.summary?.totalPrice,
  totalSuggestedPrice: state => state.summary?.totalSuggestedPrice,
  valueComparison: (state, { totalPrice, totalSuggestedPrice }) => {
    return compareValues({ totalPrice, totalSuggestedPrice })
  },
  pricePositionCount: state => state.summary?.pricePositionCount,
  pricePositionPercentages: (state, { vehiclesOnSale, pricePositionCount }) => {
    return getPercentages(vehiclesOnSale, pricePositionCount)
  },
  actionableVehicles: (state, { pricePositionCount }) =>
    pricePositionCount?.changePrice,
  desirabilityCount: state => state.summary?.desirabilityCount,
  desirabilityPercentages: (state, { vehiclesOnSale, desirabilityCount }) => {
    return getPercentages(vehiclesOnSale, desirabilityCount)
  },
  daysInStock: state => state.summary?.daysInStock,
  vehiclesSold: state => state.summary?.vehiclesSold
}
