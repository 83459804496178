import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    desktop: 1024,
    widescreen: 1216,
    fullhd: 1408
  }
})

export default Vue
