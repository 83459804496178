export default {
  setData: (state, data) => {
    state.data = data
  },
  setRequest: (state, data) => {
    state.request = data
  },
  setTimeline: (state, data) => {
    state.timeline = data
  },
  setImage: (state, data) => {
    state.image = data
  },
  setPreviousChecks: (state, data) => {
    state.previousChecks = data
  },
  setBatch: (state, data) => {
    state.batch = data
  },
  reset: (state, data) => Object.assign(state, data)
}
