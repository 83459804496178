export default {
  namespaced: true,
  state: {
    id: null,
    config: null
  },
  getters: {
    active: state => !!state.id,
    config: state => state.config
  },
  mutations: {
    close(state) {
      state.id = null
      state.config = null
    },
    open(state, [id, data]) {
      state.id = id
      if (data) {
        state.config = data
      }
    }
  }
}
