import { getOne, getPreviousChecks, getBatch } from '../services'
import state from './state'
import { cz } from '@/plugins/axios'

export default {
  fetch: async ({ commit, getters, dispatch }, uuid) => {
    dispatch('reset')

    try {
      commit('setData', await getOne(uuid))
      dispatch('fetchBatch', getters.request.batch?.uuid)
      commit('setPreviousChecks', await getPreviousChecks(getters.vin))
      await dispatch('getImage')
    } catch (err) {
      throw err
    }
  },
  fetchBatch: async ({ commit }, uuid) => {
    if (uuid) {
      const response = await getBatch(uuid)
      commit('setBatch', response)
    }
  },
  reset({ commit }) {
    commit('reset', state())
  },

  async getImage({ commit, getters }) {
    try {
      const { data } = await cz.get(`/image`, {
        params: {
          vrm: getters.vrm
        }
      })

      commit('setImage', data.image)
    } catch {
      commit('setImage', [])
    }
  }
}
