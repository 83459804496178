import store from '@/store'

const prod = process.env.NODE_ENV === 'production'

export const ga = request => {
  if (prod) {
    const trackerName = window.ga.getAll()[0].get('name')
    if (trackerName) {
      window.ga(trackerName + '.send', 'event', request)
    }
  }
}

export const track = (name, eventData = {}) => {
  if (prod && window.mixpanel) {
    const metaData = {
      userId: store.getters['auth/user']?.id,
      branchId: store.getters['auth/branch']?.id,
      branchName: store.getters['auth/branch']?.name,
      trial: store.getters['auth/isOnTrial']
    }
    window.mixpanel.track(name, { ...eventData, ...metaData })
  }
}

export const identify = id => {
  if (prod && window.mixpanel) window.mixpanel.identify(id)
}

export const set = user => {
  if (prod && window.mixpanel) {
    const org = user.organisations[0].organisation
    const experian = !!org.experianBranchId

    window.mixpanel.people.set({
      'User ID': user.id,
      $name: experian ? `Experian user #${user.id}` : user.name,
      $email: experian ? null : user.email,
      'Org ID': org.id,
      'Org Name': org.name,
      'Org Parent ID': org?.organisation?.id,
      'Org Parent Name': org?.organisation?.name
    })
  }
}

export default { track, identify, set }
