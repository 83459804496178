declare module 'vue/types/options' {
  export interface ComponentOptions<V extends Vue> {
    wait?: VueWait
  }
}

declare module 'vue/types/vue' {
  export interface Vue {
    $wait: VueWait
  }
}

import Vue from 'vue'
import VueWait from 'vue-wait'

Vue.use(VueWait)

export default Vue
