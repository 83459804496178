import { guard } from 'utils/middleware'
import { registerModule } from '@/router'
import stockview from './stores'

const name = 'stockview'

export const router = {
  path: `/${name}`,
  component: { template: '<router-view/>' },
  beforeEnter: (...router) => {
    guard(name, [router, { logout: true }])
    registerModule(name, stockview)
  },
  children: [
    {
      path: '',
      meta: { title: () => 'Stockview' },
      name,
      component: () => import('modules/stockview/pages/StockviewDashboard')
    },
    {
      path: 'stock',
      meta: { title: () => 'Stockview Stock' },
      name: `${name}-stock`,
      component: () => import('modules/stockview/pages/StockviewStock')
    },
  ]
}
